import RentalList from "../rental/RentalList";

const Rental = () => {
  return (
    <div id="home" className="pt-5">
      <RentalList />
    </div>
  );
};

export default Rental;
