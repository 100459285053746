import { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import AuthContext from "./components/contexts/auth";
import Layout from "./components/layout/Layout";
import Home from "./components/pages/Home";
import Rental from "./components/pages/Rental";
//import Members from "./components/pages/Members";
import Cronicle from "./components/pages/Cronicle";

function App() {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    const loginname = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const picture = localStorage.getItem("picture");

    if (accessToken !== null) {
      authCtx.login(accessToken, email, loginname, picture);
    }
  }, [authCtx]);

  return (
    <Layout>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/rental">
          <Rental />
        </Route>
        {/* <Route path="/members">
          <Members />
        </Route>
        <Route path="/cronicle">
          <Cronicle />
        </Route>*/} 
        <Route path="/cronicle/:cronicleId">
          <Cronicle />
        </Route>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
